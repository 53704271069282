<template>
  <div class="main">
    <div class="wrap">
      <div class="left_wrap">
        <div class="image-title">
          <p>Welcome to</p>
          <p v-if="isSuplier" class="supplier">{{ getLabelUnderLogo }}</p>
        </div>
      </div>
      <div class="right_wrap" v-if="!status">
        <v-form class="right_block forgetPass_frm" @submit.prevent="submit" ref="form" lazy-validation>
          <h1 class="title-text">
            <span class="title-text-style">{{ $t("forgot_pass.title_forgot_password") }}</span>
          </h1>
          <div class="instruction forgetPass_inst mt-48">
            <span
              >{{ $t("forgot_pass.description_please_enter_email") }}<br />{{ $t("forgot_pass.description_send_link_reset") }}</span
            >
          </div>
          <div class="input-block mt-47">
            <span class="input-title">{{ $t("forgot_pass.label_email_address") }}</span>
            <input id="email" class="input-elic mb-0" v-model="email" @focus="handleFocusEmail" @blur="handleBlurEmail"  />
            <img
              v-if="email"
              class="clear-data-input-search"
              :src="iconClose"
              alt="close"
              @click="clearDataModel"
              @mouseenter="isActiveCloseIcon = true"
              @mouseleave="isActiveCloseIcon = false"
            />
            <span class="err-mess" style="display: none" id="email_error"></span>
            <img
              id="email_img"
              class="validWarning"
              src="/img/icons/warning.svg"
              style="position: absolute; right: 6px; display: none"
            />
          </div>
          <div class="input-block mt-48">
            <input class="btn-submit" :disabled="disabled" type="submit" :value="buttonSend" />
          </div>
        </v-form>
      </div>

      <div class="right_wrap" v-if="status">
        <v-form class="right_block forgetPass_frm2" @submit.prevent="submit" ref="form" lazy-validation>
          <h1 class="title-text">
            <span class="title-text-style">{{ $t("forgot_pass.title_send_complete") }}</span>
          </h1>
          <div class="instruction forgetPass_inst mt-48">
            <span
              >{{ $t("forgot_pass.description_sent_link") }}<br />{{ $t("forgot_pass.description_not_receive") }}</span
            >
          </div>
          <div class="input-block mt-46">
            <input
              class="btn-submit"
              @click="$router.push({ name: 'Login', query: $route.query })"
              type="submit"
              :value="buttonBackLogin"
            />
          </div>
        </v-form>
      </div>
    </div>
    <error-popup :dialog="dialog" :message="message" @submit="handleClose" />
  </div>
</template>

<script>
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import validate from '@/utils/validate';
import { forgotPasswordApi } from '@/api/auth';
import { validateRequired } from '@/api/others';
import { getErrorMessge } from '@/utils/messageHandle';
import { getInforPrimaryDataApiBeforeLogin } from '@/api/supplier';

export default {
  data() {
    return {
      dialog: false,
      dialogLockPass: false,
      dialogCheck: false,
      email: '',
      message: '',
      emailRules: [(v) => validate.regexMail.test(v) || ''],
      status: false,
      disabled: false,
      isActiveCloseIcon: false,
      isCfpSite: false
    };
  },
  mounted() {

    //handle check valid suplier or not
    const uuidPrimaryData = this.$route?.query?.primary_uuid
    if(uuidPrimaryData) {
      // todo: call api check primary_uuid
      this.handleCheckPrimaryDataById(uuidPrimaryData)
    }
  },
  components: {
    ErrorPopup,
  },
  methods: {
    submit() {
      if (!validateRequired(['email'])) {
        return;
      }
      this.disabled = true;
      const payload = {
        email: this.email,
        redirect_url: `${window.location.origin}/auth/reset-password`,
        primary_uuid: this.$route?.query?.primary_uuid,
      }

      if(this.$route?.query?.locale) {
        payload.locale = this.$route?.query?.locale
      }

      forgotPasswordApi(payload)
        .then(() => {
          this.status = true;
          this.disabled = false;
        })
        .catch((err) => {
          this.dialog = true;
          this.message = getErrorMessge(err);
          this.disabled = false;
        });
    },
    handleClose() {
      this.email = '';
      this.dialog = false;
    },
    handleFocusEmail() {
      const emailError = document.getElementById('email_error')
      const emailImgError = document.getElementById('email_img')
      const email = document.getElementById('email')
      email.style.border = '2px solid #327998'
      emailError.style.display = 'none'
      emailImgError.style.display = 'none'
    },
    handleBlurEmail() {
      const email = document.getElementById('email')
      email.style.border = 'none'
    },
    clearDataModel() {
      this.email = '';
    },
    async handleCheckPrimaryDataById(uuidPrimaryData) { //check uuid before login
      try {
        const responseDetail = await getInforPrimaryDataApiBeforeLogin(uuidPrimaryData)
        this.isCfpSite = responseDetail?.is_cfp;
        if(!responseDetail?.data){
          this.$router.push('/404')
        }
      } catch (error) {
        this.$router.push('/404')
      }
    }
  },
  computed: {
    iconClose() {
      if (!this.isActiveCloseIcon) {
        return require('@/assets/icons/product/close-input.svg');
      }
      return require('@/assets/icons/product/close-input-active.svg');
    },
    buttonSend() {
      return this.$t("forgot_pass.button_send");
    },
    buttonBackLogin() {
      return this.$t("forgot_pass.button_back_login");
    },
    isSuplier() {
      return this.$route?.query?.primary_uuid
    },
    getLabelUnderLogo() {
      return this.isCfpSite
        ? this.$t('supplier_products.label_under_logo_login_screen')
        : this.$t('supplier.label_under_logo_login_screen');
    },
  }
};
</script>

<style lang="scss" scoped>
@import './LoginStyle/index.scss';

.while-color {
  background: #ffffff;
}
.wrap {
  min-height: 270px;
  overflow: auto;
}
.title-text {
  span {
    position: relative !important;
    top: 1px !important;
  }
}
.input-block {
  position: relative;
}
.clear-data-input-search {
  position: absolute;
  top: 44px;
  right: 11px;
  cursor: pointer;
}
</style>
